
import http from "@/http";
import { Procedure, ProcedureResponse } from "@/interfaces";
import { date, excerpt } from "@/filters";
import Vue from "vue";

export interface DataType {
  procedures: Procedure[];
  page: number;
  lastPage: number;
}

export default Vue.extend({
  filters: {
    date,
    excerpt,
  },
  data(): DataType {
    return {
      procedures: [],
      page: 1,
      lastPage: 0,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "契約更新",
          disabled: true,
          to: "/renewals",
        },
      ];
    },
    headers() {
      return [
        {
          text: "受付日",
          value: "created_at",
        },
        {
          text: "ステータス",
          value: "status",
        },
        {
          text: "部屋",
          value: "room",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const { target } = this.$route.params;

      const params = {
        page: this.page,
        renewal: true,
      };

      const { data } = await http.get<ProcedureResponse>("procedures", {
        params,
      });

      this.procedures = data.data;
      this.lastPage = data.last_page;
    },
    click(item: Procedure) {
      const to = `/renewal/${item.id}`;

      this.$router.push(to);
    },
  },
});
